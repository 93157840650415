import React, { useEffect, useState } from "react";
import Sidebarhead from "./SidebarHead/SidebarHead";
import SidebarContent from "./SidebarContent/SidebarContent";
import styled from "styled-components";
import AddTeacher from "./AddTeacher/AddTeacher";
import { toast } from "react-toastify";

import teachersApi from "../../services/teacherService";
import { withRouter } from "react-router";

const Side = styled.div`
  width: 350px;
`;

const Sidebar = ({ history }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [isPrepareToDelete, setIsPrepareToDelete] = useState(false);
  const [male, setMale] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [initialTeachers, setInitalTeachers] = useState([]);
  const [delList, setDelList] = useState([]);

  async function fetchData() {
    const { data } = await teachersApi.getActiveTeachers();
    if (data.status === 404) setInitalTeachers([]);
    else setInitalTeachers(makeProperData(data));
  }

  async function submitDistribution(data) {
    await teachersApi.distributeTeacherQiroatlar(data);
    fetchData()
  }

  useEffect(() => {
    fetchData();
  }, [history]);

  useEffect(() => {
    let t = initialTeachers.filter((ft) => ft.isMale === male);
    setTeachers(t);
  }, [male, initialTeachers]);

  const makeProperData = (data) => {
    return data.map((d) => {
      return {
        name:
          d.args.first_name + (d.args.last_name ? ` ${d.args.last_name}` : ``),
        id: d.args.id,
        done: d.answered,
        must: d.total,
        isMale: d.is_male,
        paused: d.paused,
      };
    });
  };

  const calculateDonePercent = (done, out) => {
    return (parseInt(done) * 100) / parseInt(out);
  };

  const handleGenderClick = (gender) => {
    gender === "male" ? setMale(true) : setMale(false);
  };

  const handleDelete = async (e) => {
    if (isPrepareToDelete) {
      let leftTeachers = teachers.filter((t) => !delList.includes(t.id + ""));
      setTeachers(leftTeachers);
      try {
        await teachersApi.delListedTeachers(delList);
        fetchData();
      } catch (err) {}
      setDelList([]);
    }
    setIsPrepareToDelete((prev) => !prev);
  };

  const handleSortChange = (type) => {
    let sortedTeachers;
    if (type === "name")
      sortedTeachers = teachers.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

    if (type === "countUp")
      sortedTeachers = teachers.sort(function (a, b) {
        if (a.must - a.done > b.must - b.done) return -1;
        if (b.must - b.done > a.must - a.done) return 1;
        return 0;
      });

    if (type === "countDown")
      sortedTeachers = teachers.sort(function (a, b) {
        if (a.must - a.done > b.must - b.done) return 1;
        if (b.must - b.done > a.must - a.done) return -1;
        return 0;
      });

    setTeachers([...sortedTeachers]);
  };

  const handleAdd = () => {
    if (isPrepareToDelete) {
      // serves as cancel for delete
      setIsPrepareToDelete(false);
      setDelList([]);
      return;
    }
    setIsAdd(true);
  };

  const closeAddModal = () => {
    setIsAdd(false);
  };

  const handleDelCheckBoxSelect = (id) => {
    let tmpList = delList;
    if (!delList.includes(id)) {
      tmpList.push(id);
      setDelList([...tmpList]);
    } else {
      tmpList = delList.filter((i) => i !== id);
      setDelList([...tmpList]);
    }
  };

  const handleNewTeacher = async (addedList) => {
    try {
      await teachersApi.addListedTeachers(addedList);
      toast.success("Tanlangan Ustozlar Qoshildi");
      fetchData();
    } catch (err) {}
  };

  const pauseTeacher = async (id, pause) => {
    try {
      const { status } = await teachersApi.pauseTeacher(id, pause);
      if (status === 200) {
        const ts = teachers.map((t) =>
          t.id === id ? { ...t, paused: pause } : t
        );
        setTeachers(ts);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editTeacher = async (id, name) => {
    try {
      const { status } = await teachersApi.editTeacher(id, name);
      if (status === 200) {
        const ts = teachers.map((t) => (t.id === id ? { ...t, name } : t));
        setTeachers(ts);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Side>
      <Sidebarhead
        delList={delList}
        male={male}
        isPrepareToDelete={isPrepareToDelete}
        handleGenderClick={handleGenderClick}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        handleSortChange={handleSortChange}
      />
      <SidebarContent
        delList={delList}
        teachers={teachers}
        isPrepareToDelete={isPrepareToDelete}
        handleDelCheckBoxSelect={handleDelCheckBoxSelect}
        calculateDonePercent={calculateDonePercent}
        pauseTeacher={pauseTeacher}
        editTeacher={editTeacher}
        submitDistribution={submitDistribution}
      />
      <AddTeacher
        style={isAdd ? { display: "block" } : { display: "none" }}
        closeAddModal={closeAddModal}
        handleNewTeacher={handleNewTeacher}
      />
    </Side>
  );
};

export default withRouter(Sidebar);

import React from "react";
import styled from "styled-components";
import Content from "./Content/Content";
import Sidebar from "./Sidebar/Sidebar";

const ContentDiv = styled.div`
  width: calc(100% - 350px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const Main = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <ContentDiv>
        <Content />
      </ContentDiv>
      <div className="sidebar shadow-lg">
        <Sidebar />
      </div>
    </div>
  );
};

export default Main;

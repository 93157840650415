import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, Tooltip } from "antd";
import {
  faSortAlphaDown,
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";

const SortOptions = ({ handleSortChange }) => {
  return (
    <Radio.Group
      style={{ display: "flex", justifyContent: "center" }}
      onChange={(e) => handleSortChange(e.target.value)}
    >
      <Tooltip title="Ismi boyicha">
        <Radio.Button value="name">
          <FontAwesomeIcon icon={faSortAlphaDown} />
        </Radio.Button>
      </Tooltip>
      <Tooltip title="Eng ko'p tekshirgan">
        <Radio.Button value="countDown">
          <FontAwesomeIcon icon={faSortAmountUp} />
        </Radio.Button>
      </Tooltip>
      <Tooltip title="Eng kam tekshirgan">
        <Radio.Button value="countUp">
          <FontAwesomeIcon icon={faSortAmountDown} />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );
};

export default SortOptions;

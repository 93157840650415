import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckBox from "./CheckBox";
import { Spin } from "antd";
import api from "../../../services/teacherService";

const ResultsContainer = styled.div`
  margin-bottom: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CustomSpinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #0e8873;
  }
`;

const ResultsItem = styled.div`
  display: flex;
`;

const Results = ({ value, checkList, handleCheckBoxSelect }) => {
  const [responceStatus, setResponceStatus] = useState(0);
  const [res, setRes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.findTeachers(value);
      setResponceStatus(data.status);
      setRes(data.data === undefined ? [] : data.data);
    };
    if (value !== "" && value.trim() !== "") {
      try {
        fetchData();
      } catch (err) {}
    } else {
      setResponceStatus(0);
      setRes([]);
    }
  }, [value]);

  return (
    <ResultsContainer>
      {value !== "" && value.trim() !== "" ? (
        responceStatus === 0 ? (
          <CustomSpinner size="large" />
        ) : responceStatus === 200 ? (
          res.map((r) =>
            r ? (
              <ResultsItem key={r.id}>
                <h6 style={{ width: "200px" }}>
                  {r.first_name + " " + (r.last_name ? r.last_name : "")}
                </h6>
                <CheckBox
                  list={checkList}
                  id={r.id}
                  handleCheckBoxSelect={handleCheckBoxSelect}
                />
              </ResultsItem>
            ) : null
          )
        ) : (
          "not found"
        )
      ) : null}
    </ResultsContainer>
  );
};

export default Results;

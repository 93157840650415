import React from "react";
import styled from "styled-components";

const ManBtn = styled.button`
  border: 3px "#EFEFEF" solid;
  margin: 5px;
  display: flex;
  justify-content: center;
  height: 35px;
`;

const ManipulateBtn = ({ handleBtnClick, style, content, disabled }) => {
  return (
    <ManBtn
      disabled={disabled}
      className={`btn btn-outline-light manipulate-btn ${
        disabled ? "disbled" : ""
      }`}
      style={style}
      onClick={(e) => handleBtnClick(e)}
    >
      {content}
    </ManBtn>
  );
};

export default ManipulateBtn;

import React from "react";
import styled from "styled-components";

const Genbtn = styled.button`
  margin: 8px;
  border-radius: 5px;
`;

const GenderBtn = ({ style, content, handleGenderClick }) => {
  return (
    <Genbtn
      className="btn btn-light col gender-btn"
      style={style}
      onClick={handleGenderClick}
    >
      {content}
    </Genbtn>
  );
};

export default GenderBtn;

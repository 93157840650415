import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import IdFilter from "./IdFilter";
import CheckFilter from "./CheckFilter";
import DateFilter from "./DateFilter";
import { toast } from "react-toastify";
import moment from "moment";
import TeachersFilter from "./TechersFilter";
import {
  LoadingOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const Filters = ({ waiting, submit, clearTable, cancelReq }) => {
  const [idList, setIdList] = useState([]); // Id Filter
  const [dateRange, setDateRange] = useState([]); // Date Filter
  const [teacherList, setTeacherList] = useState([]); // Teachers Filter
  const [check, setCheck] = useState(null); // Checked Filter

  const handleSubmit = () => {
    if (
      idList.length === 0 &&
      teacherList.length === 0 &&
      dateRange.length === 0 &&
      check === null
    )
      return toast.info("Filter parametrlarini kiriting");
    submit({
      idList,
      dateRange:
        dateRange.length > 0
          ? [
              moment(dateRange[0]).format("YYYY-MM-DD"),
              moment(dateRange[1]).format("YYYY-MM-DD"),
            ]
          : [],
      teacherList,
      check,
    });
  };
  return (
    <div
      style={{
        width: "109%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "35%" }}>
          <IdFilter
            list={idList}
            addToList={(val) =>
              val === null ? setIdList([]) : setIdList([...val])
            }
            type="qiroatlar"
            placeholder="Qiroatlarni Id boyicha qidiring"
          />
        </div>
        <div style={{ width: "41%" }}>
          <TeachersFilter
            list={teacherList}
            addToList={(val) =>
              val === null ? setTeacherList([]) : setTeacherList([...val])
            }
            type="ustozlar"
            placeholder="Ustozlarni Id boyicha qidiring"
          />
        </div>
        <div style={{ width: "10%" }}>
          <CheckFilter handleSelect={(val) => setCheck(val)} value={check} />
        </div>
        <div style={{ width: "20%" }}>
          <DateFilter
            handleSelect={(val) => setDateRange(val)}
            value={dateRange}
          />
        </div>
      </div>
      <div style={{ width: "17%" }}>
        <Tooltip title="Qidirish">
          <Button
            onClick={handleSubmit}
            icon={
              waiting ? (
                <LoadingOutlined
                  style={{
                    color: "#0E8873",
                    fontSize: 20,
                    position: "relative",
                    top: -4,
                  }}
                  spin
                />
              ) : (
                <SearchOutlined
                  style={{
                    color: "#0E8873",
                    fontSize: 20,
                    position: "relative",
                    top: -4,
                  }}
                />
              )
            }
            style={{ marginLeft: 10 }}
          />
        </Tooltip>
        <Tooltip title="Filtrlarni O'chirish">
          <Button
            // style={{ backgroundColor: "#E84D40", color: "white" }}
            onClick={() => {
              setTeacherList([]);
              setIdList([]);
              setDateRange([]);
              setCheck(null);
              clearTable();
              if (waiting) cancelReq();
            }}
            icon={
              <CloseOutlined
                style={{
                  color: "#E84D40",
                  fontSize: 20,
                  position: "relative",
                  top: -4,
                }}
              />
            }
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Filters;

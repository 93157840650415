import React from "react";
import styled from "styled-components";
import ManipulateBtn from "./ManipulateBtn";
import Logout from "./Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenderBtn from "./GenderBtn";
import auth from "../../../services/authService";
import {
  faSignOutAlt,
  faTrash,
  faPlus,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import SortOptions from "./SortOptions";

const SideHead = styled.div`
  position: sticky;
  background-color: white;
  width: 350px;
  height: 150px;
`;

const delBtnStyle = (cond) =>
  cond ? { backgroundColor: "#E84D40", justifyContent: "center" } : {};

const delBtnContent = (cond) => {
  if (cond) {
    return (
      <div style={{ color: "#ffffff" }}>
        delete <FontAwesomeIcon icon={faTrash} color="#ffffff" />{" "}
      </div>
    );
  } else {
    return <FontAwesomeIcon icon={faTrash} color="#E84D40" />;
  }
};

const addBtnContent = (cond) => {
  return cond ? (
    <div style={{ color: "#ffffff" }}>
      <FontAwesomeIcon icon={faWindowClose} color="#0E8873" />{" "}
    </div>
  ) : (
    <FontAwesomeIcon icon={faPlus} color="#0E8873" />
  );
};

const genderBtnStyle = (cond) =>
  cond ? { backgroundColor: "#0E8873", color: "white" } : {};

const Sidebarhead = ({
  delList,
  male,
  isPrepareToDelete,
  handleGenderClick,
  handleDelete,
  handleAdd,
  handleSortChange,
}) => {
  const { is_admin } = auth.getCurrentUser();
  return (
    <SideHead className="shadow-sm">
      <div className="d-flex mt-3">
        <h5 className="sidebar-name m-3">Ustozlar Jamoasi</h5>
        <div className="d-flex ml-3">
          {is_admin ? (
            <ManipulateBtn
              disabled={delList.length === 0 && isPrepareToDelete}
              handleBtnClick={handleDelete}
              style={delBtnStyle(isPrepareToDelete)}
              content={delBtnContent(isPrepareToDelete)}
            />
          ) : null}
          {is_admin ? (
            <ManipulateBtn
              disabled={false}
              handleBtnClick={handleAdd}
              style={{}}
              content={addBtnContent(isPrepareToDelete)}
            />
          ) : null}
          {isPrepareToDelete ? null : (
            <Logout
              content={<FontAwesomeIcon icon={faSignOutAlt} color="#E84D40" />}
            />
          )}
        </div>
      </div>
      <div className="gender mt-2 d-flex">
        <GenderBtn
          style={genderBtnStyle(male)}
          content="Erkak"
          handleGenderClick={() => handleGenderClick("male")}
        />
        <GenderBtn
          style={genderBtnStyle(!male)}
          content="Ayol"
          handleGenderClick={() => handleGenderClick("female")}
        />
      </div>
      <SortOptions handleSortChange={handleSortChange} />
    </SideHead>
  );
};

export default Sidebarhead;

import React from "react";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
const DateFilter = ({ handleSelect, value }) => {
  return (
    <RangePicker
      value={value}
      style={{ width: "98%" }}
      onChange={handleSelect}
    />
  );
};

export default DateFilter;

import React, { useState } from "react";
import styled from "styled-components";
import { Button, TextField, ThemeProvider } from "@material-ui/core";
import sideImage from "../images/Group 9645@2x.png";
import { theme } from "./Theme";
import auth from "./../../services/authService";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const LoginDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const ImgDiv = styled.div`
  width: 30%;
`;
const FormDiv = styled.div`
  width: 70%;
`;
const LogForm = styled.div`
  display: flex;
  justify-content: center;
  height: 90%;
  padding-top: 30px;
`;

const HeadDiv = styled.div`
  display: flex;
  padding: 30px;
  height: 10%;
`;

const FirstH6 = styled.h6`
  color: #0e8873;
  margin-left: 5px;
  font-size: 25px;
`;

const CustomH1 = styled.div`
  color: #0e8873;
  font-size: 50px;
  font-weight: 700;
  border-bottom: 5px solid #0e8873;
  text-align: center;
`;

const InpuDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CustomP = styled.div`
  color: gray;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
`;

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await auth.login(username, password);
      const { state } = props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        toast.error("Foydalanuvchi topilmadi");
      }
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  if (auth.getCurrentUser()) return <Redirect to="/main" />;

  return (
    <LoginDiv>
      <FormDiv>
        <HeadDiv>
          <FirstH6>Al</FirstH6>
          <FirstH6>Quran</FirstH6>
          <FirstH6 style={{ color: "black", fontWeight: 400 }}>
            Statistics
          </FirstH6>
        </HeadDiv>
        <LogForm>
          <div>
            <ThemeProvider theme={theme}>
              <CustomH1>Sign in to Account</CustomH1>
              <form onSubmit={(e) => handleSignIn(e)}>
                <CustomP>Login parolingizni kiriting</CustomP>
                <InpuDiv>
                  <TextField
                    onChange={(e) => handleUsernameChange(e)}
                    value={username}
                    id="login"
                    label="Login"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    color="primary"
                  />
                  <TextField
                    onChange={(e) => handlePasswordChange(e)}
                    value={password}
                    id="Password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <Button
                    type="submit"
                    // onClick={handleSignIn}
                    variant="contained"
                    color="primary"
                    size="large"
                    margin="normal"
                    required
                  >
                    Sign In
                  </Button>
                </InpuDiv>
              </form>
            </ThemeProvider>
          </div>
        </LogForm>
      </FormDiv>
      <ImgDiv>
        <img src={sideImage} style={{ width: "100%" }} alt="123" />
      </ImgDiv>
    </LoginDiv>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { Select } from "antd";
import teachersApi from "./../../../../services/teacherService";

const { Option } = Select;

const TeachersFilter = ({ list, placeholder, addToList }) => {
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    async function fetchdata() {
      const { data } = await teachersApi.getActiveTeachers();
      setShowList(makeShowList(data.map((d) => d.args)));
    }
    fetchdata();
  }, []);

  const handleChange = (val) => {
    addToList(val);
  };

  const makeShowList = (data) => {
    return data?.map((d) => <Option key={d.id}>{d.first_name}</Option>);
  };

  return (
    <Select
      mode="multiple"
      allowClear
      value={list}
      style={{ width: "99%" }}
      placeholder={placeholder}
      onChange={handleChange}
      onClear={() => addToList(null)}
    >
      {showList}
    </Select>
  );
};

export default TeachersFilter;

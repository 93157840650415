import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Results from "./Results";

const fall = keyframes`
  from{
    /* top: 0; */
    opacity: 0;
  }
  to{
    /* opacity: 1; */
    top: 50%;
  }
`;

const ModalContainer = styled.div`
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
`;

const AddContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  min-height: 250px;
  width: 400px;
  background-color: white;
  z-index: 15;
  animation: ${fall} 0.5s ease;
`;

const ModalContent = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

const Instruction = styled.div`
  margin: auto;
  margin-top: 10px;
  font-size: small;
  color: grey;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin: auto;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Input = styled.input`
  border-radius: 5px;
  border: 2px solid #d4d4d4;
  outline-color: #aba5a5;
  width: 250px;
  margin: 15px auto auto;
`;

const AddTeacher = ({ style, closeAddModal, handleNewTeacher }) => {
  const [inputValue, setInputValue] = useState("");
  const [checkList, setCheckList] = useState([]);

  const handleChange = (e) => setInputValue(e.target.value);

  const handleCheckBoxSelect = (id) => {
    let tmpList = checkList;
    if (!checkList.includes(id)) {
      tmpList.push(id);
      setCheckList([...tmpList]);
    } else {
      tmpList = checkList.filter((i) => i !== id);
      setCheckList([...tmpList]);
    }
  };

  return (
    <ModalContainer style={style}>
      <AddContainer>
        <ModalContent>
          <h4 style={{ margin: "auto", fontWeight: 650 }}> Uztoz Qo'shish</h4>
          <Instruction>
            <strong>
              Ustoz qo'shish uchun ustozning <br />
              telegram id raqamini kiriting.
            </strong>
          </Instruction>
          <Input
            placeholder=" Telegram id"
            type="text"
            value={inputValue}
            onChange={handleChange}
          />
          <Results
            value={inputValue}
            checkList={checkList}
            setCheckList={setCheckList}
            handleCheckBoxSelect={handleCheckBoxSelect}
          />
          <ButtonsContainer className="buttons">
            <button
              onClick={() => {
                setCheckList([]);
                setInputValue("");
                closeAddModal();
              }}
              className="btn"
              style={{
                width: "120px",
                height: "35px",
                fontWeight: 600,
                color: "white",
                backgroundColor: "#E84D40",
              }}
            >
              Bekor qilish
            </button>
            <button
              className="btn "
              style={{
                marginLeft: "10px",
                width: "120px",
                height: "35px",
                fontWeight: 600,
                color: "white",
                backgroundColor: "#0E8873",
              }}
              onClick={async () => {
                await handleNewTeacher(checkList);
                closeAddModal();
                setCheckList([]);
                setInputValue("");
              }}
              disabled={checkList.length === 0}
            >
              Qo'shish
            </button>
          </ButtonsContainer>
        </ModalContent>
      </AddContainer>
    </ModalContainer>
  );
};

export default AddTeacher;

import React, { useEffect, useState } from "react";
import { Progress, Tooltip } from "antd";
import {
  PlayCircleFilled,
  PauseCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import qiroatlarApi from "../../../services/qiroatlarService";
import { apiUrl } from "../../../config";

const Container = styled.div`
  display: flex;
  width: min-content;
  position: relative;
`;

const Download = styled.button`
  top: -5px;
  position: absolute;
  right: -15px;
  cursor: pointer;

  border: 1px solid #0e8874;
  background-color: #0e887495;
  border-radius: 15px;
  padding: 0 10px 5px 10px;
  font-size: 8px;
`;

const useAudio = (audioData, id, type) => {
  const [audio, setAudio] = useState();
  const [playing, setPlaying] = useState(false);
  const [percent, setPercent] = useState(0);

  const initAudio = async () => {
    const content = await qiroatlarApi.audio(audioData);
    setAudio(new Audio(content.data));
  };

  const toggle = () => {
    if (!audio) {
      initAudio(audioData);
    }
    setPlaying(!playing);
  };

  const calcPercentage = (total, current) => {
    setPercent((current * 100) / total);
  };

  useEffect(() => {
    if (!audio) return;
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    if (!audio) return;
    audio.ontimeupdate = () =>
      calcPercentage(audio.duration, audio.currentTime);
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  });

  return [playing, percent, toggle];
};

const Player = ({ url, id, type }) => {
  const [playing, percent, toggle] = useAudio(url, id, type);
  const [fileGenerating, setFileGenetating] = useState(false)
  const handleDownload = async () => {
    setFileGenetating(true)
    const downloadUrl = await qiroatlarApi.audio(url, true);
    setFileGenetating(false)
    const source = `${apiUrl}/files?file_path=${downloadUrl.data}`
    const fileName = `${type === "req" ? "Qiroat" : "Javob"}_${id}.ogg`;
    fetch(source)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style = "display: none";

      if (fileName && fileName.length) a.download = fileName;
      document.body.appendChild(a);
      a.click();
    })
  };
  return (
    <Container>
      <Progress
        width={40}
        type="circle"
        strokeColor="#0e8873"
        percent={percent}
        onClick={toggle}
        format={() =>
          playing ? (
            <PauseCircleFilled style={{ color: "#0E8873" }} />
          ) : (
            <PlayCircleFilled style={{ color: "#0E8873" }} />
          )
        }
      />
      <Tooltip title="Yuklab olish">
        <Download disabled={fileGenerating} style={{ cursor: fileGenerating ? 'progress' : 'pointer'}} onClick={handleDownload}>
          <DownloadOutlined style={{ color: "#fff" }} />
        </Download>
      </Tooltip>
    </Container>
  );
};

export default Player;

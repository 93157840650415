import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Select } from "antd";
import ReactECharts from "echarts-for-react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ru";
import chartsApi from "../../services/chartServise";

moment.locale("ru");

const getGraphOptions = (time, answered, notAnswered) => {
  return {
    color: ["#0E8873", "#E84D40"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: ["Javob qaytarilgan", "Javob qaytarilmagan"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: time,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Javob qaytarilgan",
        type: "line",
        stack: "1",
        label: {
          show: true,
          position: "bottom",
        },
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: answered,
      },
      {
        name: "Javob qaytarilmagan",
        type: "line",
        stack: "1",
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: notAnswered,
      },
    ],
  };
};

const ChartContainer = styled.div`
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 10px;
  /* box-shadow: 0px 0px 3px 0px; */
  margin: 10px 20px 20px;
  box-shadow: 0px 0px 8px 0px #bdbbbb;
`;

const TotalRequests = () => {
  const [date, setDate] = useState([]);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState({});
  const [isMonthly, setIsMonthly] = useState(false);

  useEffect(() => {
    const fetchApi = async () => {
      const { data } = await chartsApi.totalRequestsApi(date, isMonthly);
      let t = 0;
      const time = [],
        answered = [],
        notAnswered = [];
      data.forEach((d) => {
        t += parseInt(d.total);
        time.push(moment(d.date).format("MM-DD"));
        answered.push(d.answered);
        notAnswered.push(d.not_answered);
      });
      setTotal(t);
      setOptions(getGraphOptions(time, answered, notAnswered));
    };
    try {
      fetchApi();
    } catch (err) {}
  }, [date, isMonthly]);

  const handleRangeSelect = (date, dateString) => {
    setDate(dateString);
  };

  function handleSelect(value) {
    value === "monthly" ? setIsMonthly(true) : setIsMonthly(false);
  }

  let defaultStart = moment().subtract(1, "month");

  moment(defaultStart).format("YYYY-MM-DD");

  return (
    <ChartContainer>
      <Row>
        <Col span={7}>
          <h6>Umumiy Qiroatlar</h6>
          <p>
            Qiroatlarning tekshirilgani boyicha <br /> statistikasi
          </p>
        </Col>
        <Col span={12}>
          <h6>{total} ta</h6>
          <Row>
            <Col sm={15} md={15} lg={15}>
              {isMonthly ? (
                <DatePicker.RangePicker
                  picker="month"
                  disabledDate={(current) => {
                    return current && current > moment().add(0, "month");
                  }}
                />
              ) : (
                <DatePicker.RangePicker
                  onChange={handleRangeSelect}
                  defaultValue={[
                    moment(defaultStart, "YYYY-MM-DD"),
                    moment(new Date(), "YYYY-MM-DD"),
                  ]}
                  format={"YYYY-MM-DD"}
                  disabledDate={(current) => {
                    return current && current > moment().add(0, "day");
                  }}
                />
              )}
            </Col>
            <Col offset={2} sm={6} md={6} lg={6}>
              <Select
                defaultValue="daily"
                style={{ width: 120 }}
                onChange={handleSelect}
              >
                <Select.Option value="daily">Kunlik</Select.Option>
                <Select.Option value="monthly">Oylik</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            // onChartReady={this.onChartReadyCallback}
            // onEvents={EventsDict}
            // opts={}
          />
        </Col>
      </Row>
    </ChartContainer>
  );
};

export default TotalRequests;

import React, { useState, useEffect } from "react";
import { Select } from "antd";
import qiroatlarApi from "./../../../../services/qiroatlarService";

const { Option } = Select;

const IdFilter = ({ addToList, placeholder, list }) => {
  const [timer, setTimer] = useState();
  const [query, setQuery] = useState("");
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await qiroatlarApi.getIds(query);
      setShowList(makeShowList(data.data));
    }
    fetchData();
  }, [query]);

  function handleChange(value) {
    addToList(value);
    setQuery("");
  }

  const onTypeSearch = (val) => {
    if (timer) clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setQuery(val);
      }, 300)
    );
  };

  const makeShowList = (data) => {
    return data?.map((d) => <Option key={d}>{d}</Option>);
  };

  return (
    <Select
      // onSearch={(value) => setQuery(value)}
      onSearch={onTypeSearch}
      mode="multiple"
      allowClear
      value={list}
      style={{ width: "99%" }}
      placeholder={placeholder}
      onChange={handleChange}
      onClear={() => addToList(null)}
    >
      {showList}
    </Select>
  );
};

export default IdFilter;

import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";

const LogoutBtn = styled.button`
  border: 3px "#EFEFEF" solid;
  margin: 5px;
  display: flex;
  justify-content: center;
  height: 35px;
  position: absolute;
  right: 0;
`;

const Logout = ({ content, history }) => {
  return (
    <LogoutBtn
      className="btn btn-outline-light manipulate-btn"
      onClick={() => {
        localStorage.removeItem("token");
        history.push("/");
      }}
    >
      {content}
    </LogoutBtn>
  );
};

export default withRouter(Logout);

import http from "./httpService";
import { apiUrl } from "../config";

const apiEndPoint = apiUrl + "/teachers";

const getActiveTeachers = () => http.get(`${apiEndPoint}/all`);
const delListedTeachers = (list) => http.put(`${apiEndPoint}/delete`, list);
const addListedTeachers = (list) => http.put(`${apiEndPoint}/new`, list);

const distributeTeacherQiroatlar = (data) =>
  http.put(`${apiEndPoint}/distribute`, data);

const findTeachers = (q) =>
  http.get(`${apiEndPoint}/find?q=${q === "" ? undefined : q}`);

const findAllTeachers = (q) =>
  http.get(`${apiEndPoint}/findall?q=${q === "" ? null : q}`);

const pauseTeacher = (id, pause) =>
  http.put(`${apiEndPoint}/pause/${id}`, { pause });

const editTeacher = (id, name) =>
  http.put(`${apiEndPoint}/edit/${id}`, { name });

const teachersApi = {
  getActiveTeachers,
  delListedTeachers,
  addListedTeachers,
  findTeachers,
  pauseTeacher,
  editTeacher,
  findAllTeachers,
  distributeTeacherQiroatlar,
};

export default teachersApi;

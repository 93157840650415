import React, { useState } from "react";
import styled from "styled-components";
import CustomTable from "./CustomTable";
import qiroatlarApi from "../../../services/qiroatlarService";
import Player from "./Player";
import Filters from "./filters/Filters";
import { toast } from "react-toastify";

const ChartContainer = styled.div`
  height: 100%;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 10px;
  margin: 3px;
  margin: 20px;
  box-shadow: 0px 0px 8px 0px #bdbbbb;
`;

const QiroatSearch = () => {
  const [readyData, setReadyData] = useState([]);
  const [waiting, setWaiting] = useState(false);

  const filterData = async (val) => {
    setWaiting(true);
    const { data } = await qiroatlarApi.filter(val);
    if (data.status === 404) toast.info("Filter boyicha ma'lumot topilmadi");
    setWaiting(false);
    if (data.length !== 0) finilizeData(data.data);
  };

  const finilizeData = (data) => {
    data.forEach((d) => {
      if (d.req_audio) {
        d.req_audio = <Player type="req" id={d.id} url={d.req_audio} />;
      } else d.req_audio = false;
      if (d.res_audio) {
        d.res_audio = <Player type="res" id={d.id} url={d.res_audio} />;
      } else d.res_audio = false;
    });
    setReadyData(data);
  };

  const cancelReq = () => {
    qiroatlarApi.cancel();
    setWaiting(false);
  };

  return (
    <ChartContainer>
      <Filters
        waiting={waiting}
        submit={filterData}
        clearTable={() => setReadyData([])}
        cancelReq={cancelReq}
      />
      <CustomTable data={readyData} />
    </ChartContainer>
  );
};

export default QiroatSearch;

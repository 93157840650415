import { createMuiTheme } from "@material-ui/core/styles";
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0e8873",
    },
    secondary: {
      main: "#0f0",
    },
  },
});

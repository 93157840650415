import http from "./httpService";
import { apiUrl } from "../config";
import moment from "moment";

const apiEndpoint = apiUrl + "/charts";

export function dailyRequestsApi(date) {
  if (date === "") date = moment().format("YYYY-MM-DD");
  return http.post(`${apiEndpoint}/daily`, { date });
}
export function gradeChartApi(date) {
  if (date === "") date = moment().format("YYYY-MM-DD");
  return http.post(`${apiEndpoint}/grade_stat`, { date });
}
export function totalRequestsApi(date, isMonthly) {
  let a = moment().subtract(1, "month");
  a = moment(a).format("YYYY-MM-DD");
  if (date.length === 0) date = [a, moment().format("YYYY-MM-DD")];
  return http.post(`${apiEndpoint}/total`, { date, isMonthly });
}

const chartsApi = {
  dailyRequestsApi,
  totalRequestsApi,
  gradeChartApi,
};

export default chartsApi;

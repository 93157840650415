import { useEffect, useState } from "react";
import systemApi from "../../services/systemService";

const Counts = ({}) => {
    const [data, setData] = useState({});
    useEffect(async() => {
        const {data} = await systemApi.getTotals()
        setData(data);
    }, []);
    return (  
    <div style={{ margin:"10px 0 -15px", alignContent: "center" }}>
            <div className="d-flex justify-content-center">
                <h6 style={{ color: "#0E8873", marginRight: 15 }}>Umumiy Qiroatlar Soni: </h6>
                <h6 style={{ color: "#E84D40" }}>{data.qiroats && new Intl.NumberFormat().format(data.qiroats)}</h6>
            </div>
            <div className="d-flex justify-content-center">
                <h6 style={{ color: "#0E8873", marginRight: 15 }}>Umumiy O'quvchilar Soni: </h6>
                <h6 style={{ color: "#E84D40" }}>{data.users && new Intl.NumberFormat().format(data.users)}</h6>
            </div>
   
   </div> 
  );
}
 
export default Counts;
import React, { useState } from "react";
import styled from "styled-components";
import TeacherProgres from "./TeacherProgres";
import { Button, Tooltip, Input, Form } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faDirections,
  faEdit,
  faPause,
  faPlay,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import DistributeModal from "./DistributeModal";

const TInfo = styled.div`
  border-radius: 5px;
  width: 90%;
  margin: 3px 0 3px 0;
  box-shadow: 0px 0px 8px 0px #bdbbbb;
  padding: 5px;
  min-height: 60px;
`;

const TeacherInfo = ({
  style,
  name,
  id,
  paused,
  done,
  must,
  calculateDonePercent,
  pauseTeacher,
  editTeacher,
  teachers,
  submitDistribution
}) => {
  done = done === null ? 0 : done;
  must = must === null ? 0 : must;
  const [edit, setEdit] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [loading, setLoading] = useState(false);
  const [distributeModal, setDistributeModal] = useState(false);
  const handleNameChange = async () => {
    setLoading(true);
    if (editedName.trim() !== name) await editTeacher(id, editedName);
    setLoading(false);
    setEdit(false);
  };
  return (
    <TInfo style={style}>
      <DistributeModal
        open={distributeModal}
        handleClose={() => setDistributeModal(false)}
        count={must - done}
        name={name}
        fromTeacherId={id}
        teachers={teachers.filter(teacher => teacher.id !== id)}
        submitDistribution={submitDistribution}
      />
      {edit ? (
        <>
          <h6>{name}</h6>
          <Form style={{ display: "flex" }} onFinish={handleNameChange}>
            <Form.Item>
              <Input
                style={{ width: 260 }}
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                icon={
                  <FontAwesomeIcon
                    color="#0e8873"
                    icon={loading ? faSpinner : faCheck}
                    spin={loading}
                  />
                }
                htmlType="submit"
              />
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <div className="d-flex p-2">
            <div className="teacher-id" style={{ width: "50%" }}>
              <h6>{name}</h6>
              <p style={{ color: "#707070", fontFamily: "Open Sans" }}>
                id: #{id}
              </p>
            </div>
            <div className="teacher-progress" style={{ width: "50%" }}>
              <TeacherProgres
                done={done}
                must={must}
                percent={calculateDonePercent(done, must)}
              />
            </div>
          </div>
          <Button.Group>
            <Tooltip title="tahrirlash" placement="left">
              <Button type="dashed" size="small" onClick={() => setEdit(true)}>
                <FontAwesomeIcon size="xs" color="#0e8873" icon={faEdit} />
              </Button>
            </Tooltip>
            <Tooltip
              title={paused ? "Qaytarish" : "Vaqtincha tohtatish"}
              placement="right"
            >
              <Button
                type="dashed"
                size="small"
                onClick={() => pauseTeacher(id, !paused)}
              >
                <FontAwesomeIcon
                  size="xs"
                  color="#E84D40"
                  icon={paused ? faPlay : faPause}
                />
              </Button>
            </Tooltip>
            <Tooltip title="Qiroatlarni taqsimlash" placement="right">
              <Button
                type="dashed"
                size="small"
                onClick={() => setDistributeModal(true)}
              >
                <FontAwesomeIcon
                  size="xs"
                  color="#E84D40"
                  icon={faDirections}
                />
              </Button>
            </Tooltip>
          </Button.Group>
        </>
      )}
    </TInfo>
  );
};

export default TeacherInfo;

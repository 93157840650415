import React from "react";
import styled from "styled-components";

const TProgress = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

const PBar = styled.div`
  background-color: #0e8873 !important;
  margin: 1px !important;
  height: 90%;
  border-radius: 20px;
`;

const TeacherProgres = ({ done, must, percent }) => {
  return (
    <TProgress>
      <div>
        <h6>
          {new Intl.NumberFormat().format(done)} / {new Intl.NumberFormat().format(must)}
        </h6>
      </div>
      <div className="progress" style={{ borderRadius: "5.25rem" }}>
        <PBar
          className="progress-bar"
          role="progressbar"
          style={{
            width: percent + "%",
          }}
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
          ></PBar>
      </div>
      <div className="mt-1">
        <strong style={{color: "#E84D40", fontSize: 11}}>
          Javob berilmagan - {new Intl.NumberFormat().format(must-done)}
        </strong>
      </div>
    </TProgress>
  );
};

export default TeacherProgres;

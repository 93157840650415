import React from "react";
import "./CheckBox.css";

const CheckBox = ({ id, style, handleCheckBoxSelect, list }) => {
  return (
    <div className="round" style={style}>
      <input
        type="checkbox"
        id={id}
        onChange={(e) => handleCheckBoxSelect(e.target.id)}
        checked={list.includes(id + "")}
      />
      <label htmlFor={id}></label>
    </div>
  );
};

export default CheckBox;

import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import systemApi from "../../services/systemService";

const SpaceIndicator = () => {
  const [percent, setPercent] = useState(0);
  const [content, setContent] = useState(0);
  const [total, setTotal] = useState(0);

  const fetchData = async () => {
    const { data, status } = await systemApi.getSpaceInfo();
    if (status === 200) {
      setContent(data.content);
      setTotal(data.total);
      setPercent(data.percent);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div style={{ marginRight: 40, alignContent: "center", display: "flex" }}>
      <div style={{ marginRight: 10, marginTop: 3 }}>
        <h6 style={{ color: "#0E8873" }}>Server Xotirasi</h6>
        <h6 style={{ color: "#E84D40" }}>
          {new Intl.NumberFormat().format(content)}GB / {new Intl.NumberFormat().format(total)}GB
        </h6>
      </div>
      <Progress
        type="dashboard"
        strokeWidth="15"
        width={60}
        percent={percent}
        strokeColor={{
          "0%": "#E84D40",
          "100%": "#0E8873",
        }}
      />
    </div>
  );
};

export default SpaceIndicator;

import React from "react";
import { Select } from "antd";

const { Option } = Select;

const CheckFilter = ({ handleSelect, value }) => {
  return (
    <Select value={value} style={{ width: "98%" }} onChange={handleSelect}>
      <Option value={true}>Tekshirgan</Option>
      <Option value={false}>Tekshirmagan</Option>
      <Option value={null}>Disabled</Option>
    </Select>
  );
};

export default CheckFilter;

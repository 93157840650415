import React, { useState, useEffect } from "react";
import { Row, Col, DatePicker } from "antd";
import ReactECharts from "echarts-for-react";
import styled from "styled-components";
import moment from "moment";
import chatsApi from "../../services/chartServise";

const getGraphoptions = (data) => {
  return {
    animation: true,
    color: ["#E84D40", "#0E8873"],
    legend: {
      bottom: "2%",
      left: "center",
      borderWidth: 1,
      itemGap: 30,
      borderRadius: 5,
      margin: 5,
    },
    series: [
      {
        name: "Stat",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "20",
            fontWeight: "bold",
          },
        },
        data: data,
      },
    ],
  };
};

const ChartContainer = styled.div`
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 10px;
  margin: 10px 20px 20px;
  box-shadow: 0px 0px 8px 0px #bdbbbb;
`;

const DailyRequests = () => {
  const [date, setDate] = useState("");
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const fetchApi = async () => {
      const { data } = await chatsApi.dailyRequestsApi(date);
      let d = data.map((item) => {
        return {
          value: item.total,
          name: item.is_male ? `Erkak: ${item.total}` : `Ayol: ${item.total}`,
        };
      });
      let t = data.length === 1 ? data[0].total :( data.length === 0 ? 0 : +data[0].total + +data[1].total);
      setTotal(t);
      setOptions(getGraphoptions(d));
    };
    try {
      fetchApi();
    } catch (err) {}
  }, [date]);
  const handleDateSelect = (date, dateString) => {
    setDate(dateString);
  };

  return (
    <ChartContainer className="">
      <Row>
        <Col span={12}>
          <h6>Kunlik Qiroatlar</h6>
          <p>
            Qiroatlarning jinsi boyicha <br /> statistikasi
          </p>
        </Col>
        <Col span={11} offset={1}>
          <h6>{total} ta</h6>
          <DatePicker
            onChange={handleDateSelect}
            defaultValue={moment(new Date(), "YYYY-MM-DD")}
            format={"YYYY-MM-DD"}
            disabledDate={(current) => {
              return current && current > moment().add(0, "day");
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            // onChartReady={this.onChartReadyCallback}
            // onEvents={EventsDict}
            // opts={}
          />
        </Col>
      </Row>
    </ChartContainer>
  );
};

export default DailyRequests;

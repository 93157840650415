import { Modal, InputNumber } from "antd";
import React, { useState } from "react";

import styled from "styled-components";

const TeacherDiv = styled.div`
  display: flex;
  margin-top: 5px;
  padding-top: 5px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d9d9d99e;
`;

const CustomP = styled.p`
  margin: 0;
  padding: 0;
`;

const DistributeModal = ({
  open,
  handleClose,
  count,
  name,
  teachers,
  fromTeacherId,
  submitDistribution
}) => {
  const [assignedTeachers, setAssignedTeachers] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [totalAssigned, setTotalAssigned] = useState(0);

  const handleDistribution = async () => {
    const readyObj = { from: fromTeacherId, to: assignedTeachers };
    submitDistribution(readyObj)
    handleClose();
    setConfirmModal(false);
    setAssignedTeachers({});
    setTotalAssigned(0)
  };

  const handleNumChange = (teacherId, num) => {
    let sum = 0;
    let newObj = { ...assignedTeachers, [teacherId]: num };
    for (const key in newObj) {
      sum += newObj[key];
    }
    if (sum > count) {
      newObj = { ...assignedTeachers, [teacherId]: count - (sum - num) };
      setTotalAssigned(count);
    }
    setTotalAssigned(sum);
    setAssignedTeachers(newObj);
  };
  return (
    <>
      <Modal
        zIndex={1001}
        title="Ma'lumotlarni tasdiqlang:"
        visible={confirmModal}
        onCancel={() => setConfirmModal(false)}
        okType="danger"
        okText="Tasdiqlash"
        cancelText="Qaytish"
        onOk={() => handleDistribution(true)}
      >
        {Object.entries(assignedTeachers).map((t) => (
          <TeacherDiv key={t[1]}>
            <CustomP>
              {teachers.find((teacher) => teacher.id === Number(t[0]))?.name}
            </CustomP>{" "}
            <CustomP>{t[1]}</CustomP>
          </TeacherDiv>
        ))}
      </Modal>
      <Modal
        title={`${name} javob berilmagan qiroatlar soni - ${count}`}
        visible={open}
        onOk={() => setConfirmModal(true)}
        onCancel={handleClose}
        okType="danger"
        okText="Taqsimlash"
        cancelText="Bekor qilish"
        destroyOnClose
        okButtonProps={{ disabled: Object.keys(assignedTeachers).length === 0 }}
      >
        <h3 className="d-flex justify-content-center">
          {count} - {totalAssigned} = {count - totalAssigned}
        </h3>
        {teachers.map(
          (teacher) =>
            !teacher.paused && (
              <TeacherDiv key={teacher.id}>
                <CustomP>
                  {teacher.name} - {teacher.must - teacher.done}
                </CustomP>
                <InputNumber
                  value={
                    assignedTeachers[teacher.id]
                      ? assignedTeachers[teacher.id]
                      : 0
                  }
                  onChange={(num) => handleNumChange(teacher.id, num)}
                  min={0}
                  status="error"
                  size="small"
                />
              </TeacherDiv>
            )
        )}
      </Modal>
    </>
  );
};

export default DistributeModal;

import React from "react";
import styled from "styled-components";
import CheckBox from "../Common/CheckBox";
import TeacherInfo from "./TeacherInfo";

const SideContent = styled.div`
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-bottom: 20px;
`;

const SidebarContent = ({
  delList,
  teachers,
  isPrepareToDelete,
  calculateDonePercent,
  handleDelCheckBoxSelect,
  pauseTeacher,
  editTeacher,
  submitDistribution
}) => {
  return (
    <SideContent>
      {teachers.map((t) => {
        return (
          <div className="teacher" key={t.id} style={{ marginTop: "-25px" }}>
            <CheckBox
              list={delList}
              handleCheckBoxSelect={handleDelCheckBoxSelect}
              id={t.id}
              style={
                isPrepareToDelete
                  ? { top: "66px", display: "block" }
                  : { top: "66px", visibility: "hidden" }
              }
            />
            <TeacherInfo
              style={{
                marginLeft: isPrepareToDelete ? "50px" : "20px",
                backgroundColor: t.paused ? "#e0e0e0" : null,
              }}
              id={t.id}
              name={t.name}
              must={t.must}
              done={t.done}
              paused={t.paused}
              calculateDonePercent={calculateDonePercent}
              pauseTeacher={pauseTeacher}
              editTeacher={editTeacher}
              teachers={teachers}
              submitDistribution={submitDistribution}
            />
          </div>
        );
      })}
    </SideContent>
  );
};

export default SidebarContent;

import http from "./httpService";
import { apiUrl } from "../config";
import axios from "axios";

const apiEndPoint = apiUrl + "/qiroatlar";
let source;

const getIds = (query) => {
  return query === ""
    ? http.get(`${apiEndPoint}/all`)
    : http.get(`${apiEndPoint}/find?q=${query}`);
};

const filter = (val) => {
  source = axios.CancelToken.source();
  const { idList, dateRange, teacherList, check } = val;
  return http.post(
    `${apiEndPoint}/filter`,
    {
      idList,
      dateRange,
      teacherList,
      check,
    },
    { cancelToken: source.token }
  );
};

const audio = (audioData,isDownload) => http.get(`${apiEndPoint}/audio`, {params: { audio: audioData, isDownload }})

const cancel = () => {
  source.cancel();
};

const qiroatlarApi = {
  getIds,
  filter,
  cancel,
  audio
};

export default qiroatlarApi;

import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router";

const onSuccess = (responce) => {
  const authCheckError =
    responce.data && responce.data.status && responce.data.status === 401;
  if (authCheckError) {
    window.location.pathname = "/login";
    localStorage.removeItem("token");
  }
  return responce;
};

const onError = (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;

  if (expectedError) {
    console.log(error);
    toast.error("Kutilmagan hatolik");
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(onSuccess, onError);

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};

export default withRouter(http);

import React from "react";
import logo from "./images/logo.png";
import styled from "styled-components";
import DailyRequests from "./DailyRequests";
import TotalRequests from "./TotalRequests";
import QiroatSearch from "./QiroatSearch/QiroatSearch";
import { Layout, Row, Col } from "antd";
import SpaceIndicator from "./SpaceIndicator";
import Counts from "./Counts";
// import GradeChart from "./GradeChart";

const H4 = styled.h4`
  color: #0e8873;
`;

const Content = () => {
  return (
    <>
      <Layout style={{ backgroundColor: "white" }}>
        <Layout.Content>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="mt-3"
          >
            <div style={{ display: "flex" }}>
              <img
                style={{ marginLeft: "35px" }}
                height="40px"
                src={logo}
                alt="al-quran-logo"
              />
              <H4 style={{ marginLeft: "35px" }}>Qiroatlar Statistikasi</H4>
            </div>
            <Counts/>
            <SpaceIndicator />
          </div>
          <div className="m-3">
            <Row>
              <Col span={10}>
                <DailyRequests />
              </Col>
              <Col offset={0} span={14}>
                <TotalRequests />
              </Col>
            </Row>
            <Row style={{ marginBottom: 30 }}>
              <Col span={24} md={24}>
                <QiroatSearch />
              </Col>
            </Row>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default Content;

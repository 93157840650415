import React from "react";
import { Table } from "antd";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "So'rov",
    dataIndex: "req_audio",
    key: "req_audio",
    render: (res) => (res ? res : "N/A"),
  },
  {
    title: "Yuborilgan Sana",
    dataIndex: "req_date",
    key: "req_date",
    render: (date) => moment(date).format("DD-MM-YYYY"),
  },
  {
    title: "Sorov Ko'rilgan",
    dataIndex: "req_seen",
    key: "req_seen",
    render: (cond) =>
      cond ? (
        <CheckCircleOutlined
          style={{ color: "#0E8873", marginLeft: "35px", fontSize: 30 }}
        />
      ) : (
        <CloseCircleOutlined
          style={{ color: "#E84D40", marginLeft: "35px", fontSize: 30 }}
        />
      ),
  },
  {
    title: "Ustoz/ID",
    dataIndex: "teacher",
    key: "teacher",
    render: (data) =>
      (data.first_name ? data.first_name : `#${data.id}`) +
      " " +
      (data.last_name ? data.last_name : ""),
  },
  {
    title: "Javob Berilgan Sana",
    dataIndex: "res_date",
    key: "res_date",
    render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "N/A"),
  },
  {
    title: "Javob",
    dataIndex: "res_audio",
    key: "res_audio",
    render: (res) => (res ? res : "N/A"),
  },
  {
    title: "Javob Berilgan",
    key: "res_audio",
    dataIndex: "res_audio",
    render: (cond) => {
      return cond ? (
        <CheckCircleOutlined
          style={{ color: "#0E8873", marginLeft: "35px", fontSize: 30 }}
        />
      ) : (
        <CloseCircleOutlined
          style={{ color: "#E84D40", marginLeft: "35px", fontSize: 30 }}
        />
      );
    },
  },
  {
    title: "Javob Ko'rilgan",
    key: "res_seen",
    dataIndex: "res_seen",
    render: (cond) =>
      cond ? (
        <CheckCircleOutlined
          style={{ color: "#0E8873", marginLeft: "35px", fontSize: 30 }}
        />
      ) : (
        <CloseCircleOutlined
          style={{ color: "#E84D40", marginLeft: "35px", fontSize: 30 }}
        />
      ),
  },
  {
    title: "Baho",
    key: "grade",
    dataIndex: "grade",
    render: (cond) => (cond ? cond : "N/A"),
  },
];

const CustomTable = ({ data }) => (
  <Table
    scroll={{ y: 240 }}
    rowKey={(data) => data.id}
    columns={columns}
    dataSource={data}
    style={{ width: "99%", marginTop: 10 }}
    pagination={false}
  />
);
export default CustomTable;

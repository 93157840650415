import http from "./httpService";
import { apiUrl } from "../config";

const apiEndPoint = apiUrl + "/system";

const getSpaceInfo = () => http.get(apiEndPoint + "/space");
const getTotals = () => http.get(apiEndPoint + "/totals");

const systemApi = { getSpaceInfo, getTotals };

export default systemApi;

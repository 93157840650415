import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect } from "react-router-dom";
import Main from "./Components/Main";
import Login from "./Components/Login/Login";
import NotFound from "./Components/NotFound";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute path="/main" component={Main} />
        <Route path="/not-found" component={NotFound} />
        <Redirect form="/" exact to="/login" />
        <Redirect to="/not-found" />
      </Switch>
    </>
  );
}

export default App;
